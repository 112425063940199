export const environment = {
	production: true,
	release: '4.8.7',
	baseUrl: "https://carestart.web.app",// "http://go.carestart.com.au",
	carestartApiUri: "https://carestart-api.azurewebsites.net/api/",	
	tokenKey: 'token',
	apiKey: 'x533-krR##;55%23s-2232ccF-332',
	syncFusionLicenseKey: 'ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Sd0xjWntecnBWRmVV',
	abnLookupUri: "https://abr.business.gov.au/json/AbnDetails.aspx?",
	abnNameLookupUri: "https://abr.business.gov.au/json/MatchingNames.aspx?",
	openAIToken: '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',
	googleMapsApiKey: "AIzaSyBYM-ruF-yECafybexiWQSQlmcFc-pQjJA",
	mailchimpApiKey: "797d4d737a1b779078114d64b388240c-us18",
	// firebase: {
	// 	apiKey: "AIzaSyBYM-ruF-yECafybexiWQSQlmcFc-pQjJA",
	// 	authDomain: "sos-support-3f07d.firebaseapp.com",
	// 	databaseURL: "https://sos-support-3f07d.firebaseio.com",
	// 	projectId: "sos-support-3f07d",
	// 	storageBucket: "sos-support-3f07d.appspot.com",
	// 	messagingSenderId: "120578058598",
	// 	appId: "1:120578058598:web:4d629b184ef7aef1a240a7",
	// 	measurementId: "G-EWCEVG1YWG"
	// },
	google: {
		clientId: "950879280385-veitd5j6l2gt9unjm7d920arnpdtt39o.apps.googleusercontent.com",
	},
	microsoft: {
		clientId: "aec93acc-bac6-4808-a5c5-c82d63475752",
	}
};
